<template>
  <div>
    <div
      class="
        card-filter-section
        d-flex
        align-items-center
        justify-content-between
        mt-0
      "
    >
      <p class="task-timesheet m-0">
        Primary Skill
      </p>
      <div class="role-buttons d-flex flex-wrap align-items-center">
        <!-- add project type button  -->
        <table-button
          v-show="false"
          v-b-toggle.addPrimarySkill
          btn-name="Add Skill"
        />
        <!-- add project type button  -->
      </div>
      <Help
        v-show="$store.state.app.userManualList"
        code="PSkill"
      />
    </div>
    <!--Filter Start-->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="is_active"
              :options="isActiveList"
              :search-box="false"
              :hide-close="true"
              label="Active"
              icon="EditIcon"
            />
          </div>
          <option-drop-down v-model="perPage" />
        </div>
        <div class="d-flex align-items-center">
          <b-input-group
            class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px"
          >
            <b-form-input
              v-model="debouncedSearch"
              placeholder="Search by Primary Skill "
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="debouncedSearch"
                icon="XIcon"
                @click="debouncedSearch = null,search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Filter End-->
    <div class="table horizontal-long-line">

      <!-- Table start from here -->
      <TableLoader
        v-if="loading"
        :fields="fields"
      />

      <b-row v-else>
        <b-col cols="12">
          <b-table
            responsive
            :items="items"
            :fields="fields"
            class="scrollable-element tableDesigns mastertblColor"
          >
            <template #cell(active)="data">
              <b-form-checkbox
                :checked="data.item.is_active ? true : false"
                class="custom-control-success"
                name="check-button"
                size="md"
                switch
                @change="changeStatus(data.index, data.item.is_active)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </template>
            <template #cell(action)="data">
              <span>
                <feather-icon
                  icon="EditIcon"
                  class="mr-1 text-dark"
                  size="18"
                  @click="edit(data.item)"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  @click="deleteConfirmation(data.item.id)"
                />
              </span>
            </template>
          </b-table>
          <!-- table end -->
          <!-- data table complete -->
          <img
            v-if="totalCount == 0"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </b-col>
      </b-row>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0 && !loading"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->
      <!-- add project Scope form start -->
      <primary-skill-form
        ref="primarySkillForm"
        @skillList="getPrimarySkillData()"
      />
      <!-- add project Scope form complete here -->
    </div>
  </div>
</template>

<script>
import TableLoader from '@/components/loaders/table-loading.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import Help from '@/components/HelpDesk/Help.vue'
import debounce from 'lodash/debounce'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import primarySkillForm from '../../@core/components/table-from/PrimarySkillForm.vue'

export default {
  name: 'PrimarySkill',
  components: {
    TableLoader,
    TableButton,
    primarySkillForm,
    customDropdown,
    Help,
  },

  data() {
    return {
      search: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      loading: true,
      sortDesc: false,
      sortDirection: 'asc',
      is_active: 'active',
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          thStyle: { width: '250px' },
          tdStyle: { width: '400px' },
        },
      ],
      items: [],
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.getPrimarySkillData()
      }, 750),
    },
  },
  watch: {
    perPage() {
      if (this.currentPage === 1) {
        this.getPrimarySkillData()
      } else {
        this.currentPage = 1
      }
    },
    /**
     * @description on page number change Call getPrimarySkillData()
     */
    currentPage: {
      handler(value) {
        if (value) {
          this.getPrimarySkillData()
        }
      },
    },

  },
 
  mounted() {
    eventBus.$on('primary Skill', data => {
      if (data) {
        this.search = data.search
        this.is_active = data.is_active
        this.$root.$emit('bv::toggle::collapse', 'search')
      }
    })
    this.getPrimarySkillData()
  },
  methods: {
    /**
     * @description Get Primary skill List
     * @method POST
     * @returns items (variable)(primary skill list)
     */
    async getPrimarySkillData() {
      this.loading = true

      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        sort_order: this.sortDesc ? 'desc' : 'asc',
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/primary-skill',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response

        this.items = data.primary_skills
        this.totalCount = data.count
      }
      this.loading = false
    },
    /**
     * @description For Edit Primary skill pass data of Primary skill to Primary skill table form
     * @emits edit-client
     */
    edit(data) {
      this.$refs.primarySkillForm.id = data.id
      this.$refs.primarySkillForm.name = data.name
      this.$root.$emit('bv::toggle::collapse', 'addPrimarySkill')
    },
    /**
     * @description Change primary skill status
     * @param {index}  array index
     * @param {value}  value (true, false)
     * @returns if change then call updatestatus method
     */

    async changeStatus(index) {
      if (!this.items[index]) {
        return false
      }
      const label = this.items[index].is_active ? 'Deactive' : 'Active'
      const title = `Are you sure ${label} Skill?`
      const conformation = await this.conformationAlert(false, title, label)

      if (conformation.isConfirmed == true) {
        this.updateStatus(this.items[index])
      } else {
        this.items = []
        this.getPrimarySkillData()
      }
    },
    /**
     * @description Update Primary status
     * @method POST
     * @param {data} data(primaryskills Infomation in Array)
     * @returns Client Status update
     */
    async updateStatus(data) {
      const response = await this.getHTTPPutResponse(
        `admin/master/primary-skill/status/${data.id}`,
        { is_active: data.is_active ? 0 : 1 },
        true,
      )
      if (response && response.status == 200) {
        //
        this.getPrimarySkillData()
      }
    },
    /**
     * @description confirmation to delete Primary skill
     * @param {id} primaryskill id
     * @returns if conform to delete call deleteScope function
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed == true) {
        this.deleteScope(id)
      }
    },

    /**
     * @description delete primary skill
     * @method DELETE
     * @param {id}  primary skill id
     */
    async deleteScope(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/primary-skill/delete/${id}`,
        {},
        true,
      )
      if (response && response.status == 200) {
        this.getPrimarySkillData()
      }
    },

    // filter
    applyFilter() {
      this.getPrimarySkillData()
    },
    clearFilter() {
      this.search = null
      this.perPage = 10
      this.is_active = 'active'
      this.getPrimarySkillData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/component-css/masterSettingFilter.scss";
</style>
